import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'
import { defaultTheme } from '../theme/defaultTheme'

import {
  StackedColumnChart,
  SunburstChart,
  StackedBarChart
} from '../components/charts'
import { requestChartsData } from '../helpers'

const SentimentWrapper = styled.div`
  height: calc(100% - 6rem);
  padding: 0 2.2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const ChartsWrapper = styled.div`
  flex: 1;
  display: flex;
  .graphWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1.5rem 0 0;
    &.large {
      margin: 0 0 1.5rem 0;
    }
    &.right {
      margin: 0 0 0 1.5rem;
    }
  }
`

const Sentiment = () => {
  const { privateAxios } = useAxios()
  const { isLoadingFilters, queryParams } = useOutletContext()

  const [chartsData, setChartsData] = useState({
    data_0: [],
    requestStatus_0: 'loading',
    data_1: [],
    requestStatus_1: 'loading',
    data_2: [],
    requestStatus_2: 'loading'
  })

  useEffect(() => {
    if (isLoadingFilters) { return }
    getData()
  }, [isLoadingFilters, queryParams])

  
  const getData = async () => {
    const results = await requestChartsData(privateAxios, 
      [
        `/feelings/week?${queryParams}`, 
        `feelings/topic?chart=sunburst&${queryParams}`, 
        `/feelings/city?${queryParams}`
      ]
    )
    const tempState = {}
    if (!results.error) {
      for (var i = 0; i < results.length; i++) {
        const result = results[i]
        if (result && !result.error) {
          tempState['data_' + i] = result?.data
          tempState['requestStatus_' + i] = 'success'
        } else {
          tempState['requestStatus_' + i] = 'error'
        }
      }
      setChartsData({
        ...chartsData,
        ...tempState
      })
    } else {
      setChartsData({
        ...chartsData,
        requestStatus_0: 'error',
        requestStatus_1: 'error',
        requestStatus_2: 'error'
      })
    }
  }


	return (
		<SentimentWrapper>
      <ChartsWrapper>
        <div className='graphWrapper large'>
          <StackedColumnChart
            key='EvolutionOfFeelings'
            requestStatus={chartsData.requestStatus_0}
            titleText='Evolution of feelings'
            series={chartsData.data_0}
            colors={defaultTheme.sentimentChartColors}
            height='20%'
          />
        </div>
      </ChartsWrapper>
      <ChartsWrapper>
        <div className='graphWrapper'>
          <SunburstChart
            key='SentimentByTopics'
            requestStatus={chartsData.requestStatus_1}
            titleText='Sentiment by topics'
            series={chartsData.data_1}
            height='45%'
          />
        </div>
        <div className='graphWrapper right'>
          <StackedBarChart
            key='SentimentByCity'
            requestStatus={chartsData.requestStatus_2}
            titleText='Sentiment by city'
            series={chartsData.data_2}
            height='45%'
          />
        </div>
      </ChartsWrapper>

    </SentimentWrapper>
	)
}

export default Sentiment
