import dayjs from 'dayjs'

/* Return min and max values of array
 * @param {array} array of numbers
 * @return {result} object with min and max values
 */
export const getChartsLimits = (array, isPercent) => {
  if (!array || array.length === 0) {
    return { floor: 0, ceiling: 100 }
  }
  const min = Math.min(...array)
  const max = Math.max(...array)

  const floor = min > 1 ? min - 1 : 0
  const ceiling = isPercent ? (max < 99 ? max + 1 : 100) : max
  return { floor, ceiling }
}

/* Return promise with all requests promises
 * @param {privateAxios} axios instance
 * @param {requests} array of requests (strings)
 * @return {resultArray} promise
*/
export const requestChartsData = async (privateAxios, requests) => {
  const requestsArray = requests.map(async (url) => {
    try {
      return await privateAxios.get(url)
    } catch (e) {
      console.error(e)
      return {error : e}
    }
  })
  const resultArray = await Promise.all(requestsArray)
  return resultArray
}

/* Return object of filters categories
 * @param {filtersArray} array of objects
 * @return {resultObject} object
*/
export const groupFiltersByCategories = (filtersArray) => {
  if (!filtersArray || filtersArray.length === 0) { return {} }

  const groupFilters = filtersArray.reduce((acc, filter) => {
    const category = filter.category
    const currentCategoryFilters = acc[category] || []
    acc[category] = [ ...currentCategoryFilters, { ...filter }]
    return acc
  }, {})
  return groupFilters
}

/* Return query params string
 * @param {filtersBycategories} filters grouped by categories
 * @return {queryParam} string
*/
export const buildQueryParams = (filtersBycategories) => {
  if (!filtersBycategories) { return '' }

  const queryParam = Object.keys(filtersBycategories).reduce((stringRequest, category, index) => {
    const categoryFilters = filtersBycategories[category]
    const categoryLength = categoryFilters.length

    if (categoryLength > 1) {
      // loop over filters
      for (var i = 0; i < categoryLength; i++) {
        const filter = categoryFilters[i]
        if (filter.type === 'values') {
          // &country[]=france&country[]=portugal
          const filterValue = filter.value
          stringRequest += `&${category}[]=${filterValue === 'other' ? null : filterValue}`
        }
      }
    } else {
      const filter = categoryFilters[0]
      const filterValue = filter.value
      stringRequest += `&${category}[]=${filterValue === 'other' ? null : filterValue}`
    }

    return stringRequest
  }, '')
  return queryParam.slice(1)
}

