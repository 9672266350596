import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAuth } from '../context/AuthProvider'
import { useAxios } from '../context/AxiosProvider'

import logo from '../assets/images/gobeyond_logo_color.png'

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 2.2rem 2.2rem 1rem;
  background-color: ${props => props.theme.colors.primaryColor};
`

const MainContent = styled.div` 
  .logoWrapper {
    margin: 0 auto 3.7rem;
    text-align: center;
    > img {
      display: inline-block;
      width: 300px;
      height: auto;
    }
  }
  .formWrapper {
    margin: 0 auto;
    width: 75rem;
    padding: 3rem 5rem 2.5rem;
    background-color: ${props => props.theme.colors.whiteColor};
    border-radius: 3rem;
  }
  h1 {
    margin-bottom: 3.7rem;
    font-size: 2.3rem;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
  }
  .form {
    width: 100%;
  }
  .form_row {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5rem;
  }
  .input_wrapper {
    position: relative;
    margin: 0 5px;
    flex: 1;
    input {
      width: 100%;
      height: 55px;
      padding: 10px 15px 0;
      border-radius: 4px;
      border: solid 1px #dbdbdb;
    }
    label {
      position: absolute;
      z-index: 2;
      top: -6px;
      left: 15px;
      font-size: 1.4rem;
      color: ${props => props.theme.colors.textColor};
      opacity: 0.5;
      text-transform: uppercase;
      transform: translate(0, 26px) scale(1);
      transform-origin: left center;
      transition: all 0.3s ease-in;
      pointer-events: none;
    }
    input:focus + label,
    input.filled + label {
      transform: translate(0, 12px) scale(0.75);
    }
  }

  .form_lost-pwd {
    margin: 4rem auto 0;
    font-size: 1.2rem;
    text-align: center;
    a,
    a:hover {
      color: #3889da;
      text-decoration: underline;
    }
  }
  .form_submit {
    display: block;
    margin: 3rem auto;
    width: 200px;
    height: 50px;
    line-height: 44px;
    background-color: ${props => props.theme.colors.primaryColor};
    color: ${props => props.theme.colors.whiteColor};
    border-radius: 5px;
    box-shadow: 0 10px 15px 0 rgba(45, 73, 91, 0.3);
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.6px;
    &:hover {
      transform: translateY(-0.2px);
      box-shadow: 0px 10px 15px 4px rgba(45, 73, 91, 0.2);
    }
  }
`

const Login = () => {
  const { setAuthState } = useAuth()
  const { publicAxios } = useAxios()
  const [username, setUsername] = useState('user@voc.com')
  const [password, setPassword] = useState('password')

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()

    const result = await publicAxios.post('/login_check', {
      username,
      password
    })
    if (result && !result.error) {
      const token = result?.data?.token
      localStorage.setItem('userToken', token)
      setAuthState({
        accessToken: token,
        isAuthenticated: true
      })
      setTimeout(() => {
        navigate('/resume')
      }, 100)
    }
  }

  return (
    <LoginWrapper>
      <MainContent>
        <div className='logoWrapper'>
          <img src={logo} alt='GoBeyond - Customer Voice Dashboard' />
        </div>

        <div className='formWrapper'>
          <h1>Customer Voice</h1>
          <form onSubmit={handleSubmit} className='form'>
            <div className='form_row'>
              <div className='input_wrapper'>
                <input
                  type="text"
                  name="login"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                  required
                  autoComplete="username"
                  className={username !== '' ? 'filled' : ''}
                />
                <label>Identifier</label>
              </div>
              <div className='input_wrapper'>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  required
                  autoComplete="current-password"
                  className={password !== '' ? 'filled' : ''}
                />
                <label>Password</label>
              </div>
            </div>
            <button className='form_submit'>Login</button>
            <div className='form_lost-pwd'>Forgot password ? <a href="/">Click here</a></div>
          </form>
        </div>
      </MainContent>
    </LoginWrapper>
  )
}

export default Login
