import React from 'react'
import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AreaSplineChart = ({ requestStatus, series, yAxis, titleText = '', tooltipText = '', colors = defaultTheme.sentimentChartColors, legendEnabled = false }) => {
	
  const options = {
		chart: {
		  type: 'line',
		  margin: 0,
		  width: 150,
		  // height: 90
	  },
	  xAxis: {
	  	visible: false
	  },
	  yAxis: {
	  	visible: false,
	  	...yAxis
	  },
  	title: {
  		text: titleText
  	},
  	plotOptions: {
	    line: {
	    	marker: {
	    		enabled: false
	    	}
	    }
		},
	  legend: {
	  	enabled: legendEnabled
	  },
	  tooltip: {
	    formatter: function () {
	      return ('<span style="font-size: 8px;">' + this.point.name + '</span><br />' + tooltipText +
	            ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.y + '</span>');
	    }
	  },
	}
	return (
		<ChartWrapper>
			{
				requestStatus === 'loading'
					? <Loader />
					: requestStatus === 'error'
						? <p className='highcharts-error'>Une erreur est survenue</p>
						: <HighchartsReact
					    highcharts={Highcharts}
					    options={{
					    	...options,
					    	series,
					    	colors
					    }}
					  />
			}
    </ChartWrapper>
	)
}

export default AreaSplineChart
