import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAuth } from '../context/AuthProvider'

const Nav = styled.nav`
  flex: 1;
  > ul {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	list-style: none;
    margin-left: 0.5rem;

  	> li {
  		margin: 0 0 0 2.5rem;
  		text-align: center;
      > a {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: ${props => props.theme.colors.tertiaryColor};
        &.active,
        &:hover {
          color: ${props => props.theme.colors.whiteColor};
        }
        &.disabled {
          pointer-events: none;
          &:hover {
            color: ${props => props.theme.colors.tertiaryColor};
          }
        }
      }
  	}
  }
`

const MainNav = () => {
  const { authState } = useAuth()
	return (
		<Nav>
      {
        authState.isAuthenticated
          ? <ul className='mainNav--auth'>
            <li>
              <NavLink
                to={`resume`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >Resume</NavLink>
            </li>
            <li>
              <NavLink
                to={`distribution`}
                className={({ isActive }) => isActive ? 'active disabled' : 'disabled'}
              >Distribution</NavLink>
            </li>
            <li>
              <NavLink
                to={`topics`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >Topics</NavLink>
            </li>
            <li>
              <NavLink
                to={`relation`}
                className={({ isActive }) => isActive ? 'active disabled' : 'disabled'}
              >Relation</NavLink>
            </li>
            <li>
              <NavLink
                to={`sentiment`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >Sentiment</NavLink>
            </li>
            <li>
              <NavLink
                to={`world-detail`}
                className={({ isActive }) => isActive ? 'active disabled' : 'disabled'}
              >World detail</NavLink>
            </li>
            <li>
              <NavLink
                to={`explore`}
                className={({ isActive }) => isActive ? 'active disabled' : 'disabled'}
              >Explore</NavLink>
            </li>
          </ul>
          : null
      }
    </Nav>
	)
}

export default MainNav
