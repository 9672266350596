import React from 'react'
import styled from 'styled-components/macro'

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  > span {
    text-transform: uppercase;
    color: ${props => props.theme.colors.primaryColor};
    opacity: 0.4;
  }
`

const Loader = () => {
  return (
    <LoaderWrapper>
      <span>Loading ...</span>
    </LoaderWrapper>
	)
}

export default Loader
