import React from 'react'
import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  // padding: 2rem;
`

const vhToPxRatio = window.innerHeight / 100

const StackedColumnChart = ({
  requestStatus,
  stacking = 'percent',
  titleText,
  series,
  colors = defaultTheme.topicsChartColors,
  legendEnabled = false,
  height,
  xAxisTitle = ''
}) => {
  const options = {
    chart: {
      type: 'column',
      // height: height || 37 * vhToPxRatio,
      height: height || (400 / 715 * 100) + '%',
    },
    plotOptions: {
      column: { stacking }
    },
    xAxis: {
      labels: {
        align: 'center',
        style: {
          fontFamily: defaultTheme.fonts.fontFamily1, 
          fontSize: '1.3rem',
          fontWeight: 'regular'
        }
      },
      title: {
        text: xAxisTitle
      }
    },
    yAxis: {
      /* tickInterval: 50,
      labels: { format: stacking === 'percent' ? '{text}%' : '{text}' },
      title: { enabled: false } */
      visible: false
    },
    title: {
      text: titleText
    },
    legend: {
      enabled: legendEnabled
    },
    tooltip: {
      formatter: function () {
        return ('<span style="font-size: 8px;">' + this.point.name + '</span><br />' + this.series.name +
            ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.y + (stacking === 'percent' ? '%</span>' : '</span>'));
      }
    }
  }
  return (
    <ChartWrapper>
      {
        requestStatus === 'loading'
          ? <Loader />
          : requestStatus === 'error'
            ? <p className='highcharts-error'>Une erreur est survenue</p>
            : <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...options,
              colors,
              series
            }}
          />
      }
    </ChartWrapper>
  )
}

export default StackedColumnChart
