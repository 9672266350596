import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  // padding: 2rem;
`

const WordCloudChart = ({ requestStatus, titleText, series, colors = defaultTheme.topicsChartColors, legendEnabled = false }) => {
  const options = {
    chart: {
      type: 'wordcloud'
    },
    title: {
      text: titleText
    },
    legend: {
      enabled: legendEnabled
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat: '<h5>{chartTitle}</h5>' +
          '<div>{chartSubtitle}</div>' +
          '<div>{chartLongdesc}</div>' +
          '<div>{viewTableButton}</div>'
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
      formatter: function () {
        return (this.point.name + ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.point.weight + '</span>');
      }
    }
  }

  return (
    <ChartWrapper>
      {
        requestStatus === 'loading'
          ? <Loader />
          : requestStatus === 'error'
            ? <p className='highcharts-error'>Une erreur est survenue</p>
            : <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                colors,
                series
              }}
            />
      }
    </ChartWrapper>
  )
}

export default WordCloudChart
