import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'
import styled from 'styled-components/macro'

import { ReactComponent as IconCheckmark } from '../assets/images/icons/check-white.svg'
import { ReactComponent as IconArrowLeft } from '../assets/images/icons/arrow-left.svg'
import { ReactComponent as IconArrowRight } from '../assets/images/icons/arrow-right.svg'

moment.locale('en')

const DateFilterWrapper = styled.div`
  display: ${props => props.dateFiltersIsVisible ? 'flex' : 'none'};
  position: absolute;
  z-index: 10;
  top: 4.5rem;
  left: 0;
  max-width: 65rem;
  padding: 1.8rem 1.5rem;
  border-radius: 1.8rem;
  background-color: ${props => props.theme.colors.secondaryColor};
  box-shadow: 0 5px 15px 0 rgba(220, 223, 232, 0.16);
`
const ViewChoices = styled.div`
  button {
    position: relative;
    width: 120px;
    height: 30px;
    margin-bottom: 5px;
    padding-left: 40px;
    border-radius: 5px;
    color: ${props => props.theme.colors.whiteColor};
    opacity: 0.5;
    box-shadow: none;
    border: none;
    text-align: left;
    background-color: transparent;
    > .check {
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      left: 10px;
      > svg {
        display: block;
        transform: scale(0.6)
      }
    }
    &.active {
      opacity: 1;
      background-color: ${props => props.theme.colors.primaryColor};
    }
  }
`

const cellWidth = '33px'
const CalendarWrapper= styled.div`
  .react-calendar {
    width: 320px;
    margin-left: 1.5rem;
    padding: 0 1rem 1rem;
    max-width: 100%;
    background: transparent;
    border: none;
    font-family:${props => props.theme.fonts.fontFamily1};
    line-height: 1.125em;
    background-color: ${props => props.theme.colors.primaryColor};

    button {
      margin: 0;
      border: 0;
      outline: none;
      box-shadow: none;
      &:enabled:hover {
        cursor: pointer;
      }
    }
  }
  .react-calendar__navigation {
    display: flex;
    height: 36px;
    margin-bottom: 0;
    button {
      min-width: 44px;
      background: none;
      box-shadow: none;
    }
    &__label__labelText {
      font-size: 14px;
      font-weight: 400;
      color: ${props => props.theme.colors.whiteColor};
      
    }
    &__prev2-button,
    &__next2-button {
      display: none;
    }
    &__prev-button,
    &__next-button {
      color: transparent;
    }
  }

  .react-calendar__tile {
    position: relative;
    z-index: 1;
    max-width: 100%;
    height: ${cellWidth};
    background: none;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    line-height: ${cellWidth};
    color: ${props => props.theme.colors.whiteColor};
    &:disabled {
      color: rgba(255, 255, 255, 0.5);
    }
    &.react-calendar__tile--active,
    &.react-calendar__tile--hasActive {
      border-radius: 50%; 
      color: #000000;
      background-color: transparent;
      &:before {
        display: block;
        content: '';
        width: ${cellWidth};
        height: ${cellWidth};
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%; 
        background-color: ${props => props.theme.colors.whiteColor};
      }
      &.react-calendar__tile--range {
        background-color: ${props => props.theme.colors.secondaryColor};
        border-radius: 0; 
        color: ${props => props.theme.colors.whiteColor};
        &:before {
          display: none;
        }
        &.react-calendar__tile--rangeStart,
        &.react-calendar__tile--rangeEnd {
          overflow: visible !important;
          border-radius: ${props => props.currentUserView === 'week' ? 0 : '50%'};
          color: ${props => props.currentUserView === 'week' ? props.theme.colors.whiteColor : '#000000'};
          background-color: ${props => props.currentUserView === 'week' ? props.theme.colors.secondaryColor : 'transparent'};
          &:before {
            display: ${props => props.currentUserView === 'week' ? 'none' : 'block'};
            content: '';
            width: ${cellWidth};
            height: ${cellWidth};
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 50%; 
            background-color: ${props => props.theme.colors.whiteColor};
          }
          &:after {
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            position: absolute;
            z-index: -2;
            top: 0;
            background-color: ${props => props.theme.colors.secondaryColor};
          }
        }
        &.react-calendar__tile--rangeStart {
          &:after {
            right: 0;
          }
        }
        &.react-calendar__tile--rangeEnd {
          &:after {
           left: 0;
          }
          &.react-calendar__tile--rangeStart:after {
            left: auto;
            right: 0;
          }
        }
        &.react-calendar__tile--rangeBothEnds {
          &:after {
            display: none;
          }
        }
      }
    }
    &.react-calendar__tile--hover {
      background-color: ${props => props.theme.colors.secondaryColor};
    }
    &.react-calendar__tile--hoverStart,
    &.react-calendar__tile--hoverEnd {
      overflow: visible !important;
      border-radius: 50%; 
      color: #000000;
      background-color: transparent;
        &:before {
          display: block;
          content: '';
          width: ${cellWidth};
          height: ${cellWidth};
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 50%; 
          background-color: ${props => props.theme.colors.whiteColor};
        }
      &:after {
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        background-color: ${props => props.theme.colors.secondaryColor};
      }
    }
    &.react-calendar__tile--hoverStart {
      &:after {
        right: 0;
      }
    }
    &.react-calendar__tile--hoverEnd {
      &:after {
       left: 0;
      }
    }
    &.react-calendar__tile--hoverBothEnds {
      &:after {
       display: none;
      }
    }
  }

  .react-calendar__month-view {
    &__weekNumbers {
      display: ${props => props.currentUserView === 'week' ? 'block' : 'none'} !important;
      align-items: center;
      position: relative;
      flex-basis: 13% !important;
      margin-right: 1rem;
      &:before {
        display: ${props => props.currentUserView === 'week' ? 'block' : 'none'};
        content: 'Week';
        position: absolute;
        top: -23px;
        right: 9px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        font-weight: 300;
      }
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -25px;
        right: 0;
        display: block;
        width: 1px;
        height: calc(100% + 25px);
        background-color: rgba(112, 112, 112, 0.23);
      }
      .react-calendar__tile {
        display: ${props => props.currentUserView === 'week' ? 'block' : 'none'};
        flex: none !important;
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
        width: ${cellWidth};
        height: ${cellWidth};
        font-weight: 300;
        cursor: ${props => props.currentUserView === 'week' ? 'pointer' : 'auto'} !important;
        & > span {
          cursor: ${props => props.currentUserView === 'week' ? 'pointer' : 'auto'} !important;
        }
        &:hover {
          color:${props => props.currentUserView === 'week' ? '#000000' : 'rgba(255, 255, 255, 0.5)'};
          &:before {
            display: ${props => props.currentUserView === 'week' ? 'block' : 'none'};
            content: '';
            width: ${cellWidth};
            height: ${cellWidth};
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 50%; 
            background-color: ${props => props.theme.colors.whiteColor};
          }
        }
      }
    }
    &__weekdays {
      &__weekday {
        padding: 0.5em;
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        font-weight: 300;
        text-align: center;
        * {
          text-decoration: none;
        }
      }
    }
    &__days__day--neighboringMonth {
      color: rgba(255, 255, 255, 0.5);
    }
  }

`

const DateFilter = ({ dateFiltersIsVisible, activePeriod, setActivePeriod }) => {
  const startDate = activePeriod && activePeriod[0] ? activePeriod[0].toDate() : new Date()
  const endDate = activePeriod && activePeriod[1] ? activePeriod[1].toDate() : new Date()
  const [value, setValue] = useState([startDate, endDate])
  const [activeWeeks, setActiveWeeks] = useState([])
  const [currentUserView, setCurrentUserView] = useState('day')
  const [currentCalendarView, setCurrentCalendarView] = useState('month')
  const today = new Date()
  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      // skip useEffect on first load
      firstUpdate.current = false
      return
    }
    // const startDate = activePeriod && activePeriod[0] ? activePeriod[0].toDate() : new Date()
    // const endDate = activePeriod && activePeriod[1] ? activePeriod[1].toDate() : new Date()

    switch(currentUserView) {
      case 'day':
        // select today by default
        // setValue([startDate, endDate])
        // setActiveWeeks([])
        setCurrentCalendarView('month')
        break
      case 'week':
        // select current week by default
        //setActiveWeeks([moment().week()])
        //setValue([moment().startOf('week').toDate(), moment().endOf('week').toDate()])
        setCurrentCalendarView('month')
        break
      case 'month':
        // select current month by default
        // setValue([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
        // setActiveWeeks([])
        setCurrentCalendarView('year')
        break
      default:
        // select today by default
        // setValue([startDate, endDate])
        // setActiveWeeks([])
        setCurrentCalendarView('month')
        break
    }
  }, [currentUserView])

  useEffect(() => {
    if (firstUpdate.current) {
      // skip useEffect on first load
      firstUpdate.current = false
      return
    }

    let momentO = null
    let moment1 = null
    if (value[0]) {
      momentO = moment(value[0])
    }
    if (value[1]) {
      moment1 = moment(value[1])
    }
    setActivePeriod([momentO, moment1])
  }, [value])

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const onClickMonth = (newValue, event) => {
    if (value.length === 1) {
      if (moment(value[0]).isBefore(newValue)) {
        setValue([value[0], newValue])
      } else {
        setValue([newValue, value[0]])
      }
    } else {
      setValue([newValue])
    }
  }

  const onClickWeekNumber = (weekNumber, date) => {
    if (currentUserView !== 'week') { return }
    const nbActiveWeeks = activeWeeks.length
    if (nbActiveWeeks === 0) {
      // add week
      setActiveWeeks([weekNumber])
      const startWeek = moment(date).startOf('week').toDate()
      const endWeek = moment(date).endOf('week').toDate()
      setValue([startWeek, endWeek])
    } else if (activeWeeks.indexOf(weekNumber) >= 0) {
      if (activeWeeks[0] === weekNumber) {
        // remove week
        const newArray = [ ...activeWeeks].slice(1)
        if (newArray.length) {
          setActiveWeeks(newArray)
          const startWeek = moment().week(newArray[0]).startOf('week').toDate()
          const endWeek = moment().week(newArray[newArray.length - 1]).endOf('week').toDate()
          setValue([startWeek, endWeek])
        } else {
          // no week selected
          setActiveWeeks([])
          setValue([new Date(), new Date()])
        }
      } else if (activeWeeks[nbActiveWeeks - 1] === weekNumber) {
        // remove week
        const newArray = [ ...activeWeeks].slice(0, -1)
        setActiveWeeks(newArray)
        const startWeek = moment().week(newArray[0]).startOf('week').toDate()
        const endWeek = moment().week(newArray[newArray.length - 1]).endOf('week').toDate()
        setValue([startWeek, endWeek])
      } else {
        // set week as start of period
        const newArray = [ ...activeWeeks].slice(activeWeeks.indexOf(weekNumber))
        setActiveWeeks(newArray)
        const startWeek = moment().week(newArray[0]).startOf('week').toDate()
        const endWeek = moment().week(newArray[newArray.length - 1]).endOf('week').toDate()
        setValue([startWeek, endWeek])
      }
    } else {
      if (weekNumber < activeWeeks[0]) {
        // add week at start
        const newArray = []
        for (var i = weekNumber; i < activeWeeks[0]; i++) {
          newArray.push(i)
        }
        const finalArray = [...newArray, ...activeWeeks]
        setActiveWeeks(finalArray)
        const startWeek = moment().week(finalArray[0]).startOf('week').toDate()
        const endWeek = moment().week(finalArray[finalArray.length - 1]).endOf('week').toDate()
        setValue([startWeek, endWeek])
      } else {
        // add week at end
        const newArray = []
        for (var j = activeWeeks[nbActiveWeeks - 1] + 1; j <= weekNumber ; j++) {
          newArray.push(j)
        }
        const finalArray = [ ...activeWeeks, ...newArray ]
        setActiveWeeks(finalArray)
        const startWeek = moment().week(finalArray[0]).startOf('week').toDate()
        const endWeek = moment().week(finalArray[finalArray.length - 1]).endOf('week').toDate()
        setValue([startWeek, endWeek])
      }
    }
  }

  return (
    <DateFilterWrapper dateFiltersIsVisible={dateFiltersIsVisible}>
      <ViewChoices>
        <button
          onClick={() => { setCurrentUserView('day') }}
          className={`view-choice ${currentUserView === 'day' ? 'active' : ''}`}
        >
          {
            currentUserView === 'day' && 
            <span className='check'>
              <IconCheckmark alt='' />
            </span>
          }
          Daily
        </button>
        <button
          onClick={() => { setCurrentUserView('week') }}
          className={`view-choice ${currentUserView === 'week' ? 'active' : ''}`}
        >
          {
            currentUserView === 'week' && 
            <span className='check'>
              <IconCheckmark alt='' />
            </span>
          }
          Weekly
        </button>
        <button
          onClick={() => { setCurrentUserView('month') }}
          className={`view-choice ${currentUserView === 'month' ? 'active' : ''}`}
        >
          {
            currentUserView === 'month' && 
            <span className='check'>
              <IconCheckmark alt='' />
            </span>
          }
          Monthly
        </button>

      </ViewChoices>
      <CalendarWrapper currentUserView={currentUserView} activeWeeks={activeWeeks}>
        <Calendar
          locale='en'
          calendarType='US'
          onChange={onChange}
          value={value}
          showWeekNumbers
          showNavigation
          selectRange
          maxDate={today}
          view={currentCalendarView}
          prevLabel={<IconArrowLeft width={15} />}
          nextLabel={<IconArrowRight width={15} />}
          formatShortWeekday={(locale, date) => moment(date).format('dd')}
          formatMonth={(locale, date) => moment(date).format('MMM')}
          onClickMonth={onClickMonth}
          onClickWeekNumber={onClickWeekNumber}
        />
      </CalendarWrapper>
    </DateFilterWrapper>
  )
}

export default DateFilter
