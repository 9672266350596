export const defaultTheme = {
  colors: {
    primaryColor: '#254a5d', /* green */
    secondaryColor: '#1e3b4a', /* dark green */
    tertiaryColor: '#617680', /* light green */
    textColor: '#1a1b21',
    whiteColor: '#ffffff',
    backgroundColor: '#f4f5f6',
  },
  topicsChartColors: [
    '#bea2c2', // violet
    '#ead7d1', // beige
    '#c2c1a5', // marron / kaki
    '#cbdf90', // vert
    '#e9eb87', // jaune / vert
    '#99c5b5', // vert émeraude
    '#899e8b', // kaki
    '#843c3c', // marron rouge
    '#c49ebc', // violet
    '#c4b062' // kaki
  ],
  sentimentChartColors: [
    '#a9d2bf', // positive
    '#9eb3c2', // neutral
    '#c17c74' // negative
  ],
  fonts: {
    fontFamily1: 'Roboto, sans-serif'
  }
  /*topicsChartColors: [
    '#507f70', // vert foncé
    '#3b7795', // bleu foncé
    '#8fd6bd', // vert clair
    '#e8927c', // saumon
    '#8db9ca', // bleu clair
    '#fcd299', // orange clair
    '#b3b3b3', // gris
    '#843c3c', // marron rouge
    '#c49ebc', // violet
    '#c4b062' // kaki
  ],
  sentimentChartColors: [
    '#8fd6bd', // positive
    '#8db9ca', // neutral
    '#eda896' // negative
  ],
  */

}
