import Highcharts from 'highcharts'
import highchartsBoost from 'highcharts/modules/boost'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import highchartsWordcloud from 'highcharts/modules/wordcloud'
import highchartsSunburst from 'highcharts/modules/sunburst'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'

import BarChart from './BarChart'
import StackedBarChart from './StackedBarChart'
import StackedColumnChart from './StackedColumnChart'
import AreaSplineChart from './AreaSplineChart'
import LineChart from './LineChart'
import WordCloudChart from './WordCloudChart'
import SunburstChart from './SunburstChart'

import { defaultTheme } from '../../theme/defaultTheme'

if (typeof Highcharts === "object") {
  highchartsBoost(Highcharts)
  highchartsAccessibility(Highcharts)
  highchartsWordcloud(Highcharts)
  highchartsSunburst(Highcharts)
  NoDataToDisplay(Highcharts)
}

const vhToPxRatio = window.innerHeight / 100

Highcharts.setOptions({
  lang: {
    noData: "NO DATA"
  },
  chart: {
    // height: 280,
    height: (400 / 715 * 100) + '%',
    // height: 37 * vhToPxRatio,
    spacing: [20, 25, 15, 25],
    backgroundColor: defaultTheme.colors.whiteColor,
    borderRadius: 25,
    plotBackgroundColor: defaultTheme.colors.whiteColor,
    style: {
      fontFamily: defaultTheme.fonts.fontFamily1,
    },
  },
  plotOptions: {
    bar: {
      groupPadding: 0,
      pointPadding: 0.1,
      maxPointWidth: 20,
      /*series: {
        borderRadius: 20
      }*/
    },
    wordcloud: {
      rotation: {
        from: 0,
        to: 0
      },
      minFontSize: 4,
      maxFontSize: 70,
      // colorByPoint: false,
    },
    /*series: {
      animation: false
    }*/
  },
  xAxis: {
    type: 'category',
    lineWidth: 0,
    labels: {
      align: 'left',
      reserveSpace: true,
      style: {
        fontFamily: defaultTheme.fonts.fontFamily1, 
        fontSize: '1.3rem',
        fontWeight: 'bold'
      }
    }
  },
  yAxis: {
    min: 0
  },
  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      fontFamily: defaultTheme.fonts.fontFamily1,
      fontWeight: 'regular',
      textTransform: 'capitalize',
      fontSize: '1.3rem'
    },
    itemMarginBottom: 10
  },
  title: {
    align: 'left',
    // margin: 30,
    style: {
      fontFamily: defaultTheme.fonts.fontFamily1,
      fontWeight: 300,
      fontSize: '1.4rem',
      textTransform: 'uppercase',
      color: defaultTheme.colors.textColor
    }
  },
  credits: {
    enabled: false
  }
})

export { BarChart, StackedBarChart, StackedColumnChart, AreaSplineChart, LineChart, WordCloudChart, SunburstChart }
