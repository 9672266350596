import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  overflow: hidden;
  border-radius: 25px;
`

const vhToPxRatio = window.innerHeight / 100

const SunburstChart = ({
  requestStatus,
  titleText,
  series,
  colors = defaultTheme.sentimentChartColors,
  legendEnabled = false,
  height
}) => {
  const options = {
    chart: {
      type: 'sunburst',
      // height: height || 37 * vhToPxRatio,
      height: height || (400 / 715 * 100) + '%',
      margin: [0, 20, 10, 20]
    },
    title: {
      text: titleText
    },
    legend: {
      enabled: legendEnabled
    },
    navigation: {
      breadcrumbs: {
        position: {
          verticalAlign: 'bottom',
          align: 'right'
        },
        buttonTheme: {
          fill: '#f7f7f7',
          padding: 8,
          stroke: '#cccccc',
          'stroke-width': 1
        },
        showFullPath: false
      }
    },
    plotOptions: {
      sunburst: {
        allowTraversingTree: true,
        dataLabels: {
          rotationMode: 'circular',
          floating: true,
          style: {
            color: 'white',
            textOutline: 'transparent',
            fontWeight: 'light'
          }
        },
        startAngle: 180,
        levels: [ {
          level: 1,
          dataLabels: { enabled: false },
          color: 'transparent'
        },
        {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
        }]
      }
    },
    tooltip: {
      formatter: function() {
        if (this.point.id !== 'root') {
          return this.point.name + ' <b>' + this.point.value + '</b>';
        } else {
          return false;
        }
      }
    }
  }

  return (
    <ChartWrapper>
      {
          requestStatus === 'loading'
            ? <Loader />
            : requestStatus === 'error'
              ? <p className='highcharts-error'>Une erreur est survenue</p>
              : <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  colors,
                  series
                }}
              />
        }
    </ChartWrapper>
  )
}

export default SunburstChart
