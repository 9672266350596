import { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { useAuth } from './AuthProvider'

const AxiosContext = createContext()

const AxiosProvider = ({ children }) => {
  const navigate = useNavigate()
  const { getAccessToken, logout } = useAuth()

  const privateAxios = axios.create({
    baseURL: 'https://dev-voc-api.mystudiofactory.com/api',
  })

  const publicAxios = axios.create({
    baseURL: 'https://dev-voc-api.mystudiofactory.com/api',
  })

  privateAxios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  privateAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout()
        navigate('/login')
      }
      return Promise.reject(error)
    })

  const value = {
    privateAxios,
    publicAxios
  }

  return (
    <AxiosContext.Provider value={value}>
      {children}
    </AxiosContext.Provider>
  )
}

export default AxiosProvider

export const useAxios = () => useContext(AxiosContext)
