import React, { useState, useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { groupFiltersByCategories, buildQueryParams } from '../helpers'
import DateFilter from './DateFilter'
import MultiRangeSlider from './MultiRangeSlider'

import { ReactComponent as IconCalendar } from '../assets/images/icons/calendar.svg'

const ChartsViewWrapper = styled.div`
  height: calc(100vh - 11.4rem);
`

const FiltersWrapper = styled.div`
  display: flex;
  height: 3.8rem;
  margin-bottom: 2.2rem;
  padding: 0 2.2rem;
`

const DateWrapper = styled.div`
  position: relative;
  z-index: 2;
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 1.7rem 0 1rem;
    margin-right: 2rem;
    border-radius: 1.4rem;
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.colors.whiteColor};
    cursor: pointer;

    * {
    cursor: pointer;
    }

    .dateIcon,
    .dateIcon path {
      opacity: 0.5;
      transform-origin: center;
      transform: scale(0.87);
      fill: ${props => props.theme.colors.primaryColor};
    }
    .dateLabel {
      margin: 0 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: ${props => props.theme.colors.primaryColor};
    }
    .dateValue {
      font-size: 1.3rem;
      color: ${props => props.theme.colors.primaryColor};
      opacity: 0.5;
    }
    &:hover {
      background-color: ${props => props.theme.colors.whiteColor};
    }
    &.activeBtn {
      border-color: ${props => props.theme.colors.secondaryColor};
      background-color: ${props => props.theme.colors.secondaryColor};
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.16);
      .dateLabel,
      .dateValue {
        color: ${props => props.theme.colors.whiteColor};
      }
      .dateIcon,
      .dateIcon path {
        fill: ${props => props.theme.colors.whiteColor};
      }
    }
  }
`

const FiltersPannel = styled.div`
  position: relative;
  z-index: 1;

  .filtersList {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    height: 100%;
    margin-right: 2rem;
    border-radius: 1.4rem;
    border: 2px solid ${props => props.theme.colors.whiteColor};
    background-color: ${props => props.isFiltersPannelExtended ? props.theme.colors.whiteColor : 'transparent'};
    box-shadow: ${props => props.isFiltersPannelExtended ? '0 5px 15px 0 rgba(0, 0, 0, 0.16)' : 'none'};

    button {
      position: relative;
      z-index: 1;
      height: 100%;
      padding: 0 2rem;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: ${props => props.theme.colors.primaryColor};
      text-transform: capitalize;
      &:not(:last-child):after {
        content: '';
        display: block;
        width: 1px;
        height: 2.3rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: ${props => props.theme.colors.whiteColor};
      }
      &.clicked {
        color: ${props => props.theme.colors.whiteColor};
        &:before {
          content: '';
          display: block;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          position: absolute;
          z-index: -1;
          top: -2px;
          left: -2px;
          background-color: ${props => props.theme.colors.primaryColor}; 
          border-radius: 1.4rem;
        }
        &:after {
          display: none;
        }
      }
    }
    &:hover {
      background-color: ${props => props.theme.colors.whiteColor};
    }
  }

  .filtersDetails {
    display: ${props => props.isFiltersPannelExtended ? 'grid' : 'none'};
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 4.5rem;
    min-height: 20rem;
    grid-auto-flow: column;
    grid-auto-columns: minmax(20rem, 1fr);
    padding: 1.4rem 0 1.4rem 1.4rem;
    border-radius: 1.8rem;
    background-color: ${props => props.theme.colors.secondaryColor};
    box-shadow: 0 5px 15px 0 rgba(45, 73, 91, 0.4);

    .filtersDetails-group {
      margin: 0 1.4rem 0 0;
      padding: 2rem;
      border-radius: 0.5rem;
      background-color: rgba(0, 0, 0, 0.2);
      &.clicked {
        background-color: ${props => props.theme.colors.primaryColor};
      }

      .filtersDetails-group-title {
        float: left;
        margin-bottom: 1.6rem;
        font-size: 2.2rem;
        color: ${props => props.theme.colors.whiteColor};
        font-weight: 300;
        text-transform: capitalize;
      }

      .filtersDetails-group-checkbox {
        display: block;
        clear: both;
        margin-bottom: 1rem;
      }

      .filtersDetails-group-input {
        position: absolute;
        opacity: 0;
        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          color: ${props => props.theme.colors.whiteColor};
          font-size: 1.4rem;
          font-weight: 300;
        }
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 10px;
          background: transparent;
          border: 2px solid ${props => props.theme.colors.whiteColor};
          border-radius: 1px;
        }
        &:checked + label:before {
          background: ${props => props.theme.colors.whiteColor};
        }
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 6px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNi44MjYyIiBoZWlnaHQ9IjE3LjIzNjMiPgogIDxwYXRoIGQ9Ik02LjM2NzE5IDE3LjIzNjNDNi43ODcxMSAxNy4yMzYzIDcuMTE5MTQgMTcuMDUwOCA3LjM1MzUyIDE2LjY4OTVMMTYuNTgyIDIuMTU4MkMxNi43NTc4IDEuODc1IDE2LjgyNjIgMS42NjAxNiAxNi44MjYyIDEuNDM1NTVDMTYuODI2MiAwLjg5ODQzOCAxNi40NzQ2IDAuNTQ2ODc1IDE1LjkzNzUgMC41NDY4NzVDMTUuNTQ2OSAwLjU0Njg3NSAxNS4zMzIgMC42NzM4MjggMTUuMDk3NyAxLjA0NDkyTDYuMzI4MTIgMTUuMDE5NUwxLjc3NzM0IDkuMDYyNUMxLjUzMzIgOC43MjA3IDEuMjg5MDYgOC41ODM5OCAwLjkzNzUgOC41ODM5OEMwLjM4MDg1OSA4LjU4Mzk4IDAgOC45NjQ4NCAwIDkuNTAxOTVDMCA5LjcyNjU2IDAuMDk3NjU2MiA5Ljk4MDQ3IDAuMjgzMjAzIDEwLjIxNDhMNS4zNTE1NiAxNi42Njk5QzUuNjQ0NTMgMTcuMDUwOCA1Ljk0NzI3IDE3LjIzNjMgNi4zNjcxOSAxNy4yMzYzWiIgZmlsbD0iIzFhMWIyMSIgc3Ryb2tlPSIjMWExYjIxIiBmaWxsLW9wYWNpdHk9IjAuODUiLz4KPC9zdmc+Cg==");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
`

const ChartsLayoutRoute = () => {
  const { privateAxios } = useAxios()

  const [isLoadingFilters, setIsLoadingFilters] = useState(true)
  const [filtersByCategory, setFiltersByCategory] = useState({})
  const [clickedCategory, setClickedCategory] = useState('')
  const [activeFilters, setActiveFilters] = useState([])
  const [filtersQueryString, setFiltersQueryString] = useState('') // all value-type filters
  const [dmtQueryString, setDmtQueryString] = useState('') 
  const [queryParams, setQueryParams] = useState('')
  const [isFiltersPannelExtended, setFiltersPannelExtended] = useState(false)
  const [dateFiltersIsVisible, setDateFiltersIsVisible] = useState(false)
  //const firstDayOfPeriod = moment().subtract(30, 'days');
  // const lastDayOfPeriod = moment();
  const firstDayOfPeriod = moment('01-01-2022', 'DD-MM-YYYY');
  const lastDayOfPeriod = moment('30-06-2022', 'DD-MM-YYYY');
  const [activeMomentPeriod, setActiveMomentPeriod] = useState([firstDayOfPeriod, lastDayOfPeriod])

  const dmtRangeMin = 0
  const dmtRangeMax = 1000
  const [dmtFilterMin, setDmtFilterMin] = useState(dmtRangeMin)
  const [dmtFilterMax, setDmtFilterMax] = useState(dmtRangeMax)

  const clickOutsideDatePicker = (event) => {
    setDateFiltersIsVisible(false)
  }

  const clickOutsideFilters = (event) => {
    setClickedCategory('')
    setFiltersPannelExtended(false)
  }

  const datePickerRef = useOutsideClick(clickOutsideDatePicker)
  const filtersRef = useOutsideClick(clickOutsideFilters)

  useEffect(() => {
    getFilters()
  }, [filtersQueryString, activeMomentPeriod, dmtQueryString])

  const getFilters = async () => {
    setIsLoadingFilters(true)

    // 1 - build query string
    let queryString = ''
    const startDate = activeMomentPeriod && activeMomentPeriod[0] ? activeMomentPeriod[0].format('YYYY-MM-DD') : null
    const endDate = activeMomentPeriod && activeMomentPeriod[1] ? activeMomentPeriod[1].format('YYYY-MM-DD') : null

    if (startDate) {
      queryString += 'date[>%3D]=' + startDate
      if (endDate) {
        queryString += '&date[<%3D]=' + endDate
      }
    } else if (endDate) {
      queryString += 'date[<%3D]=' + endDate
    }
    if (queryString.length === 0) {
      if (filtersQueryString) {
        queryString += filtersQueryString
        if (dmtQueryString) {
          queryString += `&${dmtQueryString}`
        }
      } else if (dmtQueryString) {
        queryString += dmtQueryString
      } 
    } else {
      if (filtersQueryString) {
        queryString += `&${filtersQueryString}`
        if (dmtQueryString) {
          queryString += `&${dmtQueryString}`
        }
      } else if (dmtQueryString) {
        queryString += `&${dmtQueryString}`
      }
    }

    // 2 - build filters url
    const url = queryString ? `/filters?${queryString}` : '/filters'

    // 3 - get filters
    try {
      const result = await privateAxios.get(url)
      if (result && !result.error) {
        // format filters
        const apiData = result?.data || []
        const flatArrayFilters = []
        const groupFilters = apiData.reduce((acc, filter) => {
          if (filter.type === 'values') {
            const { name, type, values } = filter
            const valuesArray = values.map(value => {
              if (value === null) {
                return { type, category: name, value: 'other' }
              } else {
                return { type, category: name, value }
              }
            })
            flatArrayFilters.push(...valuesArray)
            const currentCategoryFilters = acc[name] || []
            acc[name] = [ ...currentCategoryFilters, ...valuesArray ]
            return acc
          } else {
            const { name, type, ...params } = filter
            flatArrayFilters.push({ type, category: name, params: { ...params } })
            const currentCategoryFilters = acc[name] || []
            acc[name] = [ ...currentCategoryFilters, { type, category: name, params: { ...params } }]
            return acc
          }
        }, {})

        setFiltersByCategory(groupFilters)

        // 4 - update context
        setQueryParams(queryString)
        setIsLoadingFilters(false)
      }
    } catch (e) {
      setIsLoadingFilters(false)
      // console.error(e)
    }
  }

  const onCategoryClick = (category) => {
    if (!isFiltersPannelExtended) {
      // open panel & set clickedCategory
      setClickedCategory(category)
      setFiltersPannelExtended(true)
    } else {
      if (clickedCategory === category) {
        // close pannel
        setClickedCategory('')
        setFiltersPannelExtended(false)
      } else {
        // change clickedCategory
        setClickedCategory(category)
      }
    }
  }

  const isActiveFilter = (filter) => {
    const { category, value } = filter
    const options = activeFilters.filter(option => (option.category === category && option.value === value))
    return options.length > 0
  }

  const toggleFilter = (filter) => {
    if (isLoadingFilters) { return }

    if (isActiveFilter(filter)) {
      // 1- remove filter from list of active filters
      const { category, value } = filter
      const filtersToKeep = activeFilters.filter(option => (option.category !== category) || (option.value !== value))
      setActiveFilters(filtersToKeep)
      // 2- group by categories to create request
      const groupFilters = groupFiltersByCategories(filtersToKeep)
      const stringParams = buildQueryParams(groupFilters)
      setFiltersQueryString(stringParams)
    } else {
      const updatedFilters = [ ...activeFilters, filter ]
      // add filter to list of active filters
      setActiveFilters(updatedFilters)
      // 2- group by categories to create request
      const groupFilters = groupFiltersByCategories(updatedFilters)
      const stringParams = buildQueryParams(groupFilters)
      setFiltersQueryString(stringParams)
    }
  }

  const onDMTChange = ({ min, max }) => {
    if (isLoadingFilters) { return }
    setDmtFilterMin(min)
    setDmtFilterMax(max)
    let stringParams = ''
    if (min) {
      stringParams += 'dmt[>%3D]=' + min
      if (max) {
        stringParams += '&dmt[<%3D]=' + max
      }
    } else if (max) {
      stringParams += 'dmt[<%3D]=' + max
    }
    setDmtQueryString(stringParams)
  }

	return (
    <ChartsViewWrapper>
      <FiltersWrapper>
        <DateWrapper ref={datePickerRef}>
          <div
            className={`buttonWrapper ${dateFiltersIsVisible ? 'activeBtn' : ''}`}
            onClick={() => { setDateFiltersIsVisible(!dateFiltersIsVisible) }}
          >
            <IconCalendar className='dateIcon' alt='' />
            <span className='dateLabel'>Date</span>
            <span className='dateValue'>
              {
                activeMomentPeriod && activeMomentPeriod[0]
                ? <span>{activeMomentPeriod[0].format('DD/MM/YY')}</span>
                : <span> </span>
              }
               - 
               {
                activeMomentPeriod && activeMomentPeriod[1]
                ? <span>{activeMomentPeriod[1].format('DD/MM/YY')}</span>
                : <span> </span>
               }
            </span>
          </div>
          <DateFilter    
            dateFiltersIsVisible={dateFiltersIsVisible}
            activePeriod={activeMomentPeriod}
            setActivePeriod={setActiveMomentPeriod}
          />
        </DateWrapper>
        <FiltersPannel
          isFiltersPannelExtended={isFiltersPannelExtended}
          ref={filtersRef}
        >
          <div className='filtersList'>
            {
              Object.keys(filtersByCategory).map(category => {
                if (category !== 'date') {
                  return (
                    <button
                      key={category}
                      onClick={() => onCategoryClick(category) }
                      className={`${clickedCategory === category ? 'clicked' : ''}`}
                    >{category}</button>
                  )
                } else return null
              })
            }
          </div>
          <div className='filtersDetails'>
            {
              Object.keys(filtersByCategory).map(category => {
                const filters = filtersByCategory[category]
                if (category !== 'date') {
                  return (
                    <fieldset
                      className={`filtersDetails-group ${clickedCategory === category ? 'clicked' : ''}`}
                      key={category}
                    >
                      <legend className='filtersDetails-group-title'>{category}</legend>
                      {
                        filters.map(filter => {
                          if (filter.type === 'values') {
                            const { category, value } = filter
                            return (
                              <div key={`${category}_${value}`} className='filtersDetails-group-checkbox'>
                                <input
                                  type="checkbox"
                                  name={category}
                                  id={`id_${value}`}
                                  className='filtersDetails-group-input'
                                  checked={isActiveFilter(filter)}
                                  onChange={() => {
                                    toggleFilter(filter)
                                  }}
                                />
                                <label htmlFor={`id_${value}`} className='filtersDetails-group-label'>
                                  {value}
                                </label>
                              </div>
                            )
                          } else {
                            return (
                              <MultiRangeSlider
                                key={category}
                                min={dmtRangeMin}
                                max={dmtRangeMax}
                                onChange={onDMTChange}
                              />
                            )
                          }
                        })
                      }
                    </fieldset>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
        </FiltersPannel>
      </FiltersWrapper>
      <Outlet context={useMemo(() => ({ isLoadingFilters, queryParams }), [isLoadingFilters, queryParams])} />
    </ChartsViewWrapper>
	)
}

export default ChartsLayoutRoute
