import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'

import {
  BarChart,
  StackedBarChart,
  StackedColumnChart,
  WordCloudChart
} from '../components/charts'
import FeelingsIndicator from '../components/FeelingsIndicator'
import ScoreIndicator from '../components/ScoreIndicator'
import { requestChartsData } from '../helpers'

const ResumeWrapper = styled.div`
  height: calc(100% - 6rem);
  padding: 0 2.2rem;
  display: flex;
  justify-content: space-between;
`

const IndicatorsWrapper = styled.div`
  flex: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    flex: 1;
    margin-bottom: 1rem;
    padding: 0.7rem 2rem;
    background-color: ${props => props.theme.colors.whiteColor};
    border-radius: 2.5rem;
  }
`

const ChartsWrapper = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;

  .graphWrapper {
    flex: 1;
    margin: 0 0 2.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Resume = () => {
  const { privateAxios } = useAxios()
  const { isLoadingFilters, queryParams } = useOutletContext()

  const [chartsData, setChartsData] = useState({
    data_0: [],
    requestStatus_0: 'loading',
    data_1: [],
    requestStatus_1: 'loading',
    data_2: [],
    requestStatus_2: 'loading',
    data_3: [],
    requestStatus_3: 'loading'
  })

  useEffect(() => {
    if (isLoadingFilters) { return }
    getData()
  }, [isLoadingFilters, queryParams])

  const getData = async () => {
    const results = await requestChartsData(privateAxios,
      [
        `topics/top?${queryParams}`,
        `/feelings/topic?chart=stacked-bar&${queryParams}`,
        `/words?limit=100&${queryParams}`,
        `topics/month?${queryParams}`
      ]
    )
    const tempState = {}
    if (!results.error) {
      for (var i = 0; i < results.length; i++) {
        const result = results[i]
        if (result && !result.error) {
          tempState['data_' + i] = result?.data
          tempState['requestStatus_' + i] = 'success'
        } else {
          tempState['requestStatus_' + i] = 'error'
        }
      }
      setChartsData({
        ...chartsData,
        ...tempState
      })
    } else {
      setChartsData({
        ...chartsData,
        requestStatus_0: 'error',
        requestStatus_1: 'error',
        requestStatus_2: 'error',
        requestStatus_3: 'error'
      })
    }
  }

	return (
		<ResumeWrapper>
      <IndicatorsWrapper>
        <FeelingsIndicator key='positive' type='positive' queryParams={queryParams} />
        <FeelingsIndicator key='neutral' type='neutral' queryParams={queryParams} />
        <FeelingsIndicator key='negative' type='negative' queryParams={queryParams} />
        <ScoreIndicator key='nps' type='nps' queryParams={queryParams} />
        <ScoreIndicator isLast key='dmt' type='dmt' queryParams={queryParams} />
      </IndicatorsWrapper>
      <ChartsWrapper>
        <div className='graphWrapper'>
          <BarChart
            key='TopTopicsDistribution' 
            requestStatus={chartsData.requestStatus_0}
            titleText='Top topics distribution'
            series={chartsData.data_0}
          />
        </div>
        <div className='graphWrapper'>
          <WordCloudChart
            key='Words' 
            requestStatus={chartsData.requestStatus_2}
            titleText='words'
            series={chartsData.data_2}
          />
        </div>
      </ChartsWrapper>

      <ChartsWrapper>
        <div className='graphWrapper'>
          <StackedBarChart
            key='TopTopicsSentiments' 
            requestStatus={chartsData.requestStatus_1}
            titleText='Top topics sentiments'
            series={chartsData.data_1}
            legendEnabled
          />
        </div>
        <div className='graphWrapper'>
          <StackedColumnChart
            key='EvolutionOfTopics' 
            requestStatus={chartsData.requestStatus_3}
            titleText='Evolution of topics'
            series={chartsData.data_3}
          />
        </div>
      </ChartsWrapper>

    </ResumeWrapper>
	)
}

export default Resume
