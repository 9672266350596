import React from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAuth } from '../context/AuthProvider'

const HomeStyle = styled.div`
  margin: auto;
  padding: 10% 1rem 0;
  max-width: 320px;
  text-align: center;

  > a {
    display: block;
    margin: 2rem auto;
    padding: 1rem 2rem;
    color: white;
    background-color: ${props => (props.theme.colors.secondaryColor)};
  }
`

const Home = () => {
  const { authState } = useAuth()

  return (
    <HomeStyle>
      {
        authState.isAuthenticated
          ? <Navigate to="/resume" replace={true} />
          : <Navigate to="/login" replace={true} />
      }
    </HomeStyle>
  )
}

export default Home
