import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAuth } from '../context/AuthProvider'

const Nav = styled.nav`

  > ul {
  	display: flex;
  	flex-direction: row;
  	justify-content: flex-end;
  	align-items: center;
  	list-style: none;

  	> li {
  		margin-left: 1.3rem;
  		text-align: right;
      > a {
        font-size: 1.3rem;
        font-weight: 300;
        color: ${props => (props.theme.colors.whiteColor)};
        &.active,
        &:hover {
          color: ${props => (props.theme.colors.tertiaryColor)};
        }
      }
  	}

    button {
      margin: 0 0 0 1.3rem;
      font-size: 1.3rem;
      font-weight: 300;
      color: ${props => (props.theme.colors.whiteColor)};
      &:hover {
        color: ${props => (props.theme.colors.tertiaryColor)};
      }
    }
  }
`

const SecondNav = () => {
  const { authState, logout } = useAuth()

	return (
    <Nav>
      {
        authState.isAuthenticated
          ? <ul>
            {/* <li>
              <NavLink
                to={`help`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >Help</NavLink>
            </li> */}
            <li>
              <button onClick={logout}>Logout</button>
            </li>
          </ul>
          : <ul>
          <li>
              <NavLink
                to={`login`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >Login</NavLink>
            </li>
          </ul>
      }
    </Nav>
	)
}

export default SecondNav
