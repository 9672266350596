import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'
import App from './App'
import ErrorPage from './views/ErrorPage'
import Home from './views/Home'
import Login from './views/Login'
import Resume from './views/Resume'
import Topics from './views/Topics'
import Sentiment from './views/Sentiment'

import PrivateOutlet from './components/PrivateOutlet'
import ChartsLayoutRoute from './components/ChartsLayoutRoute'


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: '*',
        element: <PrivateOutlet />,
        children:[
          {
            element:  <ChartsLayoutRoute />,
            children:[
              {
                path: 'resume',
                element: <Resume />
              },
              {
                path: 'distribution',
                element: <p>Distribution</p>
              },
              {
                path: 'topics',
                element: <Topics />
              },
              {
                path: 'relation',
                element: <p>Relation</p>
              },
              {
                path: 'sentiment',
                element: <Sentiment />
              },
              {
                path: 'world-detail',
                element: <p>World detail</p>
              },
              {
                path: 'explore',
                element: <p>Explore</p>
              },
              {
                path: 'help',
                element: <p>Help</p>
              }
            ]
          }
        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
