import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'

import { getChartsLimits } from '../helpers'
import { LineChart } from '../components/charts'
import { defaultTheme } from '../theme/defaultTheme'

const ContentWrapper = styled.div`
  display: flex;
`

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .h1 {
    margin: 0 73.4px 5px 0px;
    font-family: ${props => props.theme.fonts.fontFamily1};
    font-size: 1.4rem;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
  }
`

const KeyNumber = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily1};
  font-size: 3.5rem;
  font-weight: 500;
`

const ScoreIndicator = ({ type, queryParams, isLast = false }) => {
  const { privateAxios } = useAxios()

  const [scoreValue, setScoreValue] = useState(0)
  const [scoreValueRequestStatus, setScoreValueRequestStatus] = useState('loading')
  const [scoreMonthly, setScoreMonthly] = useState([])
  const [scoreMonthlyRequestStatus, setScoreMonthlyRequestStatus] = useState('loading')
  const [scoreLimits, setScoreLimits] = useState({ floor: 0, ceiling: 100 })

  useEffect(() => {
    const scoreValueRequest = `/${type}?${queryParams}`
    const scoreMonthlyRequest = `/${type}/month?${queryParams}`
    const getData = async () => {
      const requestsData = [scoreValueRequest, scoreMonthlyRequest]
      const requestsArray = requestsData.map(async (url) => {
        try {
          return await privateAxios.get(url)
        } catch (e) {
          console.error(e)
          return {error : e}
        }
      })
      const [r1, r2] = await Promise.all(requestsArray)
      if (r1 && !r1.error) {
        setScoreValue(r1?.data?.y)
        setScoreValueRequestStatus('success')
      } else {
        setScoreValueRequestStatus('error')
      }
      if (r2 && !r2.error) {
        const serie = r2?.data
        const serieData = serie?.[0]?.data
        const valuesArray = serieData.map(data => data.y)
        setScoreMonthly(serie)
        setScoreLimits(getChartsLimits(valuesArray))
        setScoreMonthlyRequestStatus('success')
      } else {
        setScoreMonthlyRequestStatus('error')
      }
    }
    getData()
  }, [queryParams])

	return (
		<ContentWrapper isLast={isLast}>
      <Infos>
        <h1 className='h1'>{type}</h1>
        {
          scoreValueRequestStatus === 'success' &&
          <KeyNumber>{scoreValue}</KeyNumber>
        }
        {
          scoreValueRequestStatus === 'error' &&
          <p className='highcharts-error'>Une erreur est survenue</p>
        }
      </Infos>
      <LineChart
        requestStatus={scoreMonthlyRequestStatus}
        series={scoreMonthly}
        colors={[defaultTheme.colors.primaryColor]}
        yAxis={scoreLimits}
        titleText=''
        tooltipText={type.toUpperCase()}
      />
    </ContentWrapper>
	)
}

export default ScoreIndicator
