import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components/macro'

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .slider {
    position: relative;
    width: 100%;
  }

  .slider__track,
  .slider__range {
    position: absolute;
    top: 60px;
    background-color: #a9d2bf;
  }
  .slider__track {
    height: 2px;
    opacity: 0.2;
    width: 100%;
    z-index: 1;
  }
  .slider__range {
    height: 4px;
    z-index: 2;
  }
  .slider__values-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .slider__left-value,
  .slider__right-value {
    flex: 1;
    height: 30px;
    line-height: 30px;
    padding: 0 1rem;
    color: ${props => props.theme.colors.whiteColor};
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    text-align: center;
    border-radius: 2px;
  }
  .slider__left-value {
    left: 6px;
  }
  .slider__right-value {
    right: -4px;
  }
  .slider__separator {
    display: block;
    width: 10px;
    margin: 0 10px;
    height: 1px;
    background-color: ${props => props.theme.colors.whiteColor};
  }
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .thumb {
    pointer-events: none;
    position: absolute;
    top: 58px;
    height: 0;
    width: 100%;
    outline: none;
  }
  .thumb--left {
    z-index: 3;
  }
  .thumb--right {
    z-index: 4;
  }
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: ${props => props.theme.colors.whiteColor};
    border: solid 3px #244659;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: ${props => props.theme.colors.whiteColor};
    border: solid 3px #244659;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`

const MultiRangeSlider = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  const onMouseUp = (type, event) => {
    if (type === 'min') {
      const value = Math.min(Number(event.target.value), maxVal - 1);
      if (onMouseUp) {
        onChange({ min: value, max: maxVal })
      }
    } else {
      const value = Math.max(Number(event.target.value), minVal + 1);
      if (onMouseUp) {
        onChange({ min: minVal, max: value })
      }
    }
      
  }

  return (
    <SliderWrapper>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        onMouseUp={(event) => { onMouseUp('min', event) }}
        className='thumb thumb--left'
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        onMouseUp={(event) => { onMouseUp('max', event) }}
        className='thumb thumb--right'
      />

      <div className='slider'>
        <div className='slider__values-wrapper'>
          <div className='slider__left-value'>{minVal}</div>
          <div className='slider__separator' />
          <div className='slider__right-value'>{maxVal}</div>
        </div>
        <div className='slider__track' />
        <div ref={range} className='slider__range' />
      </div>
    </SliderWrapper>
  )
}

export default MultiRangeSlider
