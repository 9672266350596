import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'

import { getChartsLimits } from '../helpers'
import { AreaSplineChart } from '../components/charts'
import { defaultTheme } from '../theme/defaultTheme'

const ContentWrapper = styled.div`
  display: flex;
`

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .h1 {
    margin: 0 0 5px 0;
    font-family: ${props => props.theme.fonts.fontFamily1};
    font-size: 1.4rem;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
  }
`

const KeyNumber = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily1};
  font-size: 3.5rem;
  font-weight: 500;
`

const FeelingsIndicator = ({ type, queryParams, isLast = false }) => {
  const { privateAxios } = useAxios()
  const [feelingsValue, setFeelingsValue] = useState(0)
  const [feelingsValueRequestStatus, setFeelingsValueRequestStatus] = useState('loading')
  const [feelingsMonthly, setFeelingsMonthly] = useState([])
  const [feelingsMonthlyRequestStatus, setFeelingsMonthlyRequestStatus] = useState('loading')
  const [feelingsLimits, setFeelingLimits] = useState({ floor: 0, ceiling: 100 })
  const [feelingsColor, setFeelingsColor] = useState('#fff')

  useEffect(() => {
    if (type === 'positive') {
      setFeelingsColor(defaultTheme.sentimentChartColors[0])
    } else if (type === 'neutral') {
      setFeelingsColor(defaultTheme.sentimentChartColors[1])
    } else {
      setFeelingsColor(defaultTheme.sentimentChartColors[2])
    }
    const feelingsValueRequest = `/feelings/${type}?${queryParams}`
    const feelingsMonthlyRequest = `/feelings/${type}/month?${queryParams}`
    const getData = async () => {
      const requestsData = [feelingsValueRequest, feelingsMonthlyRequest]
      const requestsArray = requestsData.map(async (url) => {
        try {
          return await privateAxios.get(url)
        } catch (e) {
          console.error(e)
          return {error : e}
        }
      })
      const [r1, r2] = await Promise.all(requestsArray)
      if (r1 && !r1.error) {
        setFeelingsValue(r1?.data?.y)
        setFeelingsValueRequestStatus('success')
      } else {
        setFeelingsValueRequestStatus('error')
      }
      if (r2 && !r2.error) {
        const serie = r2?.data
        const serieData = serie?.[0]?.data
        const valuesArray = serieData.map(data => data.y)
        setFeelingsMonthly(serie)
        setFeelingLimits(getChartsLimits(valuesArray))
        setFeelingsMonthlyRequestStatus('success')
      } else {
        setFeelingsMonthlyRequestStatus('error')
      }
    }
    getData()
  }, [queryParams])

	return (
		<ContentWrapper isLast={isLast}>
      <Infos>
        <h1 className='h1'>{type}</h1>
        {
          feelingsValueRequestStatus === 'success' &&
          <KeyNumber>{feelingsValue} %</KeyNumber>
        }
        {
          feelingsValueRequestStatus === 'error' &&
          <p className='highcharts-error'>Une erreur est survenue</p>
        }
      </Infos>
      <AreaSplineChart
        requestStatus={feelingsMonthlyRequestStatus}
        series={feelingsMonthly}
        colors={[feelingsColor]}
        yAxis={feelingsLimits}
        titleText=''
        tooltipText={`${type} rate`}
      />
    </ContentWrapper>
	)
}

export default FeelingsIndicator
