import { createContext, useContext, useState } from 'react'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const userToken = localStorage.getItem('userToken') || ''

  const [authState, setAuthState] = useState({
    accessToken: userToken || '',
    isAuthenticated: Boolean(userToken),
  })

  const logout = () => {
    localStorage.setItem('userToken', '')
    setAuthState({
      accessToken: '',
      isAuthenticated: false,
    })
  }

  const getAccessToken = () => {
    return authState.accessToken
  }

  const value = {
    authState,
    setAuthState,
    getAccessToken,
    logout
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
