import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useAxios } from '../context/AxiosProvider'
import { defaultTheme } from '../theme/defaultTheme'

import {
  StackedColumnChart,
  StackedBarChart
} from '../components/charts'
import { requestChartsData } from '../helpers'

const TopicsWrapper = styled.div`
  height: calc(100% - 6rem);
  padding: 0 2.2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const ChartsWrapper = styled.div`
  flex: 1;
  display: flex;
  .graphWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1.5rem 0 0;
    &.large {
      margin: 0 0 1.5rem 0;
    }
    &.right {
      margin: 0 0 0 1.5rem;
    }
  }
`

const Topics = () => {
  const { privateAxios } = useAxios()
  const { isLoadingFilters, queryParams } = useOutletContext()

  const [chartsData, setChartsData] = useState({
    data_0: [],
    requestStatus_0: 'loading',
    data_1: [],
    requestStatus_1: 'loading',
    data_2: [],
    requestStatus_2: 'loading'
  })

  useEffect(() => {
    if (isLoadingFilters) { return }
    getData()
  }, [isLoadingFilters, queryParams])

  const getData = async () => {
    const results = await requestChartsData(privateAxios, 
      [
        `/topics/week?${queryParams}`, 
        `/topics/product-category?${queryParams}`, 
        `/topics/nps?${queryParams}`
      ]
    )
    const tempState = {}
    if (!results.error) {
      for (var i = 0; i < results.length; i++) {
        const result = results[i]
        if (result && !result.error) {
          tempState['data_' + i] = result?.data
          tempState['requestStatus_' + i] = 'success'
        } else {
          tempState['requestStatus_' + i] = 'error'
        }
      }
      setChartsData({
        ...chartsData,
        ...tempState
      })
    } else {
      setChartsData({
        ...chartsData,
        requestStatus_0: 'error',
        requestStatus_1: 'error',
        requestStatus_2: 'error'
      })
    }
  }

	return (
		<TopicsWrapper>
      <ChartsWrapper>
       <div className='graphWrapper large'>
          <StackedColumnChart
            key='EvolutionOfTopics'
            requestStatus={chartsData.requestStatus_0}
            titleText='Evolution of topics'
            series={chartsData.data_0}
            legendEnabled
            height='20%'
            xAxisTitle='By week'
          />
        </div>
      </ChartsWrapper>
      <ChartsWrapper>
        <div className='graphWrapper'>
          <StackedBarChart
            key='TopicsByProduct'
            requestStatus={chartsData.requestStatus_1}
            titleText='Topics by product category'
            series={chartsData.data_1}
            stacking='normal'
            height='45%'
            colors={defaultTheme.topicsChartColors}
            tickInterval={10}
          />
        </div>
        <div className='graphWrapper right'>
          <StackedBarChart
            key='TopicsByNPS'
            requestStatus={chartsData.requestStatus_2}
            titleText='Topics by NPS'
            series={chartsData.data_2}
            height='45%'
            colors={defaultTheme.topicsChartColors}
          />
        </div>
      </ChartsWrapper>

    </TopicsWrapper>
	)
}

export default Topics
