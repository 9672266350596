import React from 'react'
import styled from 'styled-components/macro'

import { useAuth } from '../context/AuthProvider'

import MainNav from './MainNav'
import SecondNav from './SecondNav'
import logo from '../assets/images/gobeyond_logo_gb.png'

const AppHeader = styled.header`
  padding: 2.2rem;

  .AppHeader__contentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6rem;
    padding-right: 2.4rem;
    border-radius: 2rem;
    background-color: ${props => props.theme.colors.secondaryColor};
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.16);
  }
  .AppHeader__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.8rem;
    height: 100%;
    border-radius: 2rem 0 0 2rem;
    background-color: ${props => props.theme.colors.primaryColor};
    > img {
      display: inline-block;
      width: 36px;
      height: auto;
    }
  }
`

const Header = () => {
  const { authState } = useAuth()
  if (!authState.isAuthenticated) { return null }
	return (
		<AppHeader>
      <div className='AppHeader__contentWrapper'>
        <h1 className='AppHeader__title'>
          <img src={logo} alt='GoBeyond - Customer Voice Dashboard' />
        </h1>
        <MainNav />
        <SecondNav />
      </div>
    </AppHeader>
	)
}

export default Header
