import React from 'react'
import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  // padding: 2rem;
`

const StackedBarChart = ({
  requestStatus,
  stacking = 'percent',
  titleText,
  series,
  colors = defaultTheme.sentimentChartColors,
  legendEnabled = false,
  height,
  tickInterval
}) => {
	const options = {
		chart: {
		  type: 'bar',
      // height: height || 37 * vhToPxRatio,
      height: height || (400 / 715 * 100) + '%',
	  },
		plotOptions: {
	    bar: {
	    	stacking
	    }
		},
		yAxis: {
	  	tickInterval: tickInterval || 50,
      labels: { format: stacking === 'percent' ? '{text}%' : '{text}' },
	  	title: { enabled: false }
	  },
	  title: {
	  	text: titleText
	  },
	  legend: {
  		enabled: legendEnabled
  	},
	  tooltip: {
	    formatter: function () {
	      return ('<span style="font-size: 8px;">' + this.point.name + '</span><br />' + this.series.name +
	            ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.y + (stacking === 'percent' ? '%</span>' : '</span>'));
	    }
	  },
	}

	return (
		<ChartWrapper>
			{
				requestStatus === 'loading'
					? <Loader />
					: requestStatus === 'error'
						? <p className='highcharts-error'>Une erreur est survenue</p>
						: <HighchartsReact
					    highcharts={Highcharts}
					    options={{
					    	...options,
					    	colors,
					    	series
					    }}
					  />
			}
    </ChartWrapper>
	)
}

export default StackedBarChart
