import { Navigate, useLocation, Outlet } from 'react-router-dom'

import { useAuth } from '../context/AuthProvider'

const PrivateOutlet = () => {
  const { authState } = useAuth()
  const { pathname } = useLocation()

  return (
    authState.isAuthenticated
      ? <Outlet />
      : <Navigate to="/login" state={{ from: pathname }} replace />
  )
}

export default PrivateOutlet
