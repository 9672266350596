import React from 'react'
import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

const AreaSplineChart = ({ requestStatus, series, yAxis, titleText = '', tooltipText = '', colors = defaultTheme.sentimentChartColors, legendEnabled = false }) => {
	const options = {
		chart: {
		  type: 'areaspline',
		  margin: [0, 0, 0, 0],
		  spacing: [0, 0, 0, 0],
		  width: 140,
		  // height: 90
	  },
	  xAxis: {
	  	visible: false,
      type: 'datetime'
	  },
	  yAxis: {
	  	...yAxis,
      visible: false,
	  },
	  title: {
	  	text: titleText
	  },
		plotOptions: {
	    areaspline: {
	    	marker: {
	    		enabled: false
	    	}
	    },
	    series: {
	      fillColor: {
	      	linearGradient: [0, 0, 0, 60],
	        stops: [
	        	[0, Highcharts.color(colors[0]).setOpacity(0.1).get('rgba')],
	        	[1, '#ffffff']
	        ]
	      }
	    }
		},
	  legend: {
	  	enabled: legendEnabled
	  },
	  tooltip: {
	    formatter: function () {
	      return '<span style="font-size: 8px;">' + this.point.name + '</span><br />' + tooltipText + ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.y + '%</span>';
	    }
	  },
	}

	return (
		<ChartWrapper>
			{
				requestStatus === 'loading'
					? <Loader />
					: requestStatus === 'error'
						? <p className='highcharts-error'>Une erreur est survenue</p>
						: <HighchartsReact
					    highcharts={Highcharts}
					    options={{
					    	...options,
					    	series,
					    	colors
					    }}
					  />
			}
    </ChartWrapper>
	)
}

export default AreaSplineChart
