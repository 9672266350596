import React from 'react'
import styled from 'styled-components/macro'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { defaultTheme } from '../../theme/defaultTheme'
import Loader from '../Loader'

const ChartWrapper = styled.div`
  // padding: 2rem;
`

const BarChart = ({ requestStatus, titleText, series, colors = defaultTheme.topicsChartColors, legendEnabled = false, unit='%' }) => {
	const options = {
		chart: {
		  type: 'bar'
	  },
	  yAxis: {
	  	visible: false
	  },
	  title: {
  		text: titleText
  	},
	  plotOptions: {
	    bar: {
	      dataLabels: {
	        enabled: true,
	        inside: true,
	        align: 'left',
	        x: 390, //offset
	        format: '{y}' + unit,
	        style: {
	        	fontFamily: defaultTheme.fonts.fontFamily1,
	        	fontWeight: 'regular',
	        	fontSize: '1.3rem',
	        	color: defaultTheme.colors.textColor
	        }
	      },
	      colorByPoint: true
	    },
		},
	  legend: {
	  	enabled: legendEnabled
	  },
		tooltip: {
	    formatter: function () {
	      return (this.point.name + ' <span style="font-weight: bold; color: ' + this.point.color + ';">' + this.y + (unit || '') + '</span>');
	    }
	  }
	}

	return (
		<ChartWrapper>
			{
				requestStatus === 'loading'
					? <Loader />
					: (requestStatus === 'error'
						? <p className='highcharts-error'>Une erreur est survenue</p>
						: <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                colors,
                series
              }}
          />
          )
			}
    </ChartWrapper>
	)
}

export default BarChart
