import React from 'react'
import { Outlet } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components/macro'

import AuthProvider from './context/AuthProvider'
import AxiosProvider from './context/AxiosProvider'

import GlobalStyle from './theme/globalStyle'
import { defaultTheme } from './theme/defaultTheme'
import Header from './components/Header'
const AppWrapper = styled.div`
  // padding: 2.2rem 2.2rem 1rem;
`

const App = () => {
  return (
    <AuthProvider>
      <AxiosProvider>
        <ThemeProvider theme={defaultTheme}>
          <>
            <GlobalStyle theme={defaultTheme} />
            <AppWrapper>
              <Header />
              <main>
                <Outlet />
              </main>
            </AppWrapper>
          </>
        </ ThemeProvider>
      </AxiosProvider>
    </AuthProvider>
  )
}

export default App
