import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`
	html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, main, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    font-size: 62.5%; /* 1rem = 10px */
  }

  body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: ${props => (props.theme.fonts.fontFamily1)};
    color: ${props => (props.theme.colors.textColor)};
    font-size: 16px;
    background-color: ${props => (props.theme.colors.backgroundColor)};
  }

  #root {
    height: 100%;
  }

  a {
    text-decoration: none;
    color: ${props => (props.theme.colors.primaryColor)};
    &.active,
    &:hover {
      color: ${props => (props.theme.colors.secondaryColor)};
    }
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    font-family: ${props => (props.theme.fonts.fontFamily1)};
    &:hover {
      cursor: pointer;
    }
  }

  .mobile-only {
    display: block;
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }

  .highcharts-label.highcharts-no-data {
    color: ${props => (props.theme.colors.secondaryColor)} !important;
    opacity: 0.4;
    font-family: ${props => (props.theme.fonts.fontFamily1)};
  }

  .highcharts-label.highcharts-no-data text {
    color: ${props => (props.theme.colors.secondaryColor)} !important;
    font-family: ${props => (props.theme.fonts.fontFamily1)};
    font-weight: 300 !important;
  }

  .highcharts-error {
    color: ${props => (props.theme.colors.secondaryColor)};
    font-family: ${props => (props.theme.fonts.fontFamily1)};
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
  }
`
export default GlobalStyle